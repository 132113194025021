<template>
  <b-modal
    id="modal-create-votes"
    :visible="visible"
    hide-footer
    centered
    size="lg"
    @hidden="setItem"
  >
  <template #modal-header>
    <language-selector-header :title="$t('votes.create')" @closeModal="closeModal" @selectLanguage="(language)=>selectedLanguage = language"/>
  </template>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(handleCreateItem)">
        <validation-provider #default="validationContext" name=" " rules="required">
          <b-form-group :label="$t('form-create-item.title')" label-for="name">
            <b-form-input
              id="name"
              v-model="item.name[selectedLanguage]"
              class="my-50"
              rules="required"
              :state="getValidationState(validationContext)"
              :placeholder="$t('form.type-placeholder')"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-form-group :label="$t('form-create-item.description')" label-for="description">
          <b-form-textarea
            v-model="item.description[selectedLanguage]"
            type="text"
            :rows="5"
            :placeholder="$t('form.type-placeholder')"
          />
        </b-form-group>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isSending"
          type="submit"
        >
          <span
            v-if="isSending"
            class="d-flex "
          ><b-spinner
             class="mr-1"
             small
             variant="white"
             label="Loading..."
           />
            {{ $t('form.actions.save') }}
          </span>
          <span v-else>{{ $t('form.actions.save') }}</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required } from '@validations';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue'
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import FormTranslationTable from '@core/components/form-translation-table/FormTranslationTable.vue';

export default {
  name: 'QuestionVotesModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    FormTranslationTable,
    LanguageSelectorHeader
  },
  mixins: [ToastNotificationsMixin],
  model: {
    prop: 'visible',
  },
  props: {
    visible: { Type: Boolean, default: false },
  },
  data() {
    return {
      isSending: false,
      required,
      selectedLanguage: '',
      item: {
        name: {},
        description: {},
      },
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  methods: {
    async handleCreateItem() {
      this.isSending = true;
      try {
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'forms',
            customName: 'votes',
            requestConfig: {
              name: this.item.name,
              description: this.item.description,
              type: 'vote',
            },
          },
        });
        this.name = {};
        this.description = {};
        this.isSending = false;
        this.closeModal();
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
        this.isSending = false;
      }
    },
    setItem() {
      this.item = { name: {}, description: {} };
    },
    closeModal() {
      this.$bvModal.hide('modal-create-votes');
    },
  },
};
</script>

<style lang="scss" scoped>
.text-editor::v-deep {
  height: auto;
  .ql-editor {
    min-height: 96px;
  }
}
</style>
